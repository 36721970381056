import { ArticleType } from 'helpers/strapi/types'
import { HeadMetatags } from 'routes/components/Layout'

export function ArticleSeoMetatags({
  article,
}: {
  article: ArticleType
  isDraft?: boolean
}) {
  const {
    title,
    description,
    cover,
    seo_title,
    seo_description,
    seo_image,
    publishedAt,
    createdAt,
    author,
    category,
    tags,
  } = article

  const openGraphImageUrl = seo_image?.original?.url || cover?.original?.url

  return (
    <HeadMetatags
      title={seo_title || title || ''}
      description={seo_description || description || ''}
      openGraphImageUrl={openGraphImageUrl}
      type={'article'}
    >

      {publishedAt && (
        <meta
          property='og:article:published_time'
          content={publishedAt}
          key={'article:published_time'}
        />
      )}
      {createdAt && (
        <meta
          property='og:article:modified_time'
          content={createdAt}
          key={'article:modified_time'}
        />
      )}
      {author && (
        <meta
          property='og:article:author'
          content={author?.name || author.username}
          key={'article:author'}
        />
      )}
      {category?.name && (
        <meta
          property='og:article:section'
          content={category?.name}
          key={'article:section'}
        />
      )}
      {(tags || [])?.map((tag) => (
        <meta
          property='og:article:tag'
          content={tag.name}
          key={'article:tag'}
        />
      ))}
    </HeadMetatags>
  )
}
