import Head from 'next/head'
import { PropsWithChildren } from 'react'

const DEFAULT_TITLE = 'AI-powered Integration Framework'
const DEFAULT_DESCRIPTION = "Integration platforms don't work. Scale your in-house integration development with our AI-powered framework."
const DEFAULT_OPEN_GRAPH_IMAGE = getAbsoluteUrl('images/image-opengraph-202403.png')

interface HeadMetatagsProps {
  title?: string
  description?: string
  openGraphImageUrl?: string
  type?: 'website' | 'article'
  canonical?: string
}

export function HeadMetatags({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
  openGraphImageUrl = DEFAULT_OPEN_GRAPH_IMAGE,
  type = 'website',
  canonical,
  children,
}: PropsWithChildren<HeadMetatagsProps>) {
  const pageTitle = `${title} | Integration.app`

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name='description' content={description} />
      <meta property='og:type' content={type} key='og:type' />
      <meta property='og:title' content={title} key='og:title' />
      <meta property='og:description' content={description} key='og:description' />
      <meta property='og:image' content={openGraphImageUrl} key='og:image' />
      <meta name='twitter:card' content='summary_large_image' key='twitter:card' />
      <meta property='twitter:domain' content='integration.app' key='twitter:domain' />
      <meta name='twitter:title' content={title} key='twitter:title' />
      <meta name='twitter:description' content={description} key='twitter:description' />
      <meta name='twitter:image' content={openGraphImageUrl} key='twitter:image' />
      {canonical && <link rel="canonical" href={canonical} />}
      {children}
    </Head>
  )
}

export const HeadMetatagViewport = () => <meta name='viewport' content='minimum-scale=1, initial-scale=1, width=device-width' />

export function HeadMetatagsFavicons() {
  return (
    <>
      <link rel='icon' type='image/svg+xml' href='/favicon/favicon.svg' />
      <link rel='icon' type='image/png' sizes='32x32' href='/favicon/favicon-32x32.png' />
      <link rel='icon' type='image/png' sizes='16x16' href='/favicon/favicon-16x16.png' />
    </>
  )
}

export function HeadMetatagsPreloadFonts() {
  const fonts = [
    '/fonts/logo.ttf', '/fonts/clash-grotesk-400.woff2', '/fonts/clash-grotesk-500.woff2',
    '/fonts/satoshi-400.woff2', '/fonts/satoshi-500.woff2', '/fonts/fira-code-400.woff2',
    '/fonts/digital-numbers-400.woff'
  ]
  return (
    <>
      {fonts.map(font => (
        <link rel='preload' href={font} as='font' type='font/woff2' crossOrigin='anonymous' key={font} />
      ))}
    </>
  )
}

function getAbsoluteUrl(url: string) {
  const origin = process.env.NODE_ENV === 'development' ? 'http://localhost:8000/' : 'https://integration.app/'
  return `${origin}${url}`
}
